export default {
  methods: {
    async confirmDeletion({ text, title = "您確定要刪除嗎？", confirmButtonText = "確定刪除", cancelButtonText = "不要刪除" }) {
      const result = await this.$swal({
        title,
        text,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonColor: "#3085d6",
        cancelButtonText,
        confirmButtonColor: "#d33",
        confirmButtonText,
        reverseButtons: true,
      });

      return result && result.value === true // NOTE: 結果 true 為確定刪除, false 為不要刪除
    },
  },
}
