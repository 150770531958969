<template>
  <b-button
    variant="inverse-dark"
    @click="$refs.modal.show()"
  >
    &nbsp; <!-- NOTE: add a space to align icon in center, can be remove in Vue 3, since Vue 3 allow multiple root node in a single component -->
    <i class="fa fa-link"></i>
    <!-- NOTE: b-modal should be move to the same level as b-button in Vue 3 -->
    <b-modal
      ref="modal"
      footer-class="d-flex justify-content-center"
      hide-header
    >
      <b-textarea
        ref="url"
        v-b-tooltip.click="'已複製'"
        v-model="url"
        @click="copyAssignUrl"
        class="form-control"
      ></b-textarea>
      <template #modal-footer>
        <b-button
          size="lg"
          variant="danger"
          @click="$refs.modal.hide()"
          >關閉</b-button
        >
      </template>
    </b-modal>
  </b-button>
</template>

<script>
export default {
  props: {
    url: {
      required: true,
      type: String,
    },
  },
  methods: {
    copyAssignUrl() {
      const inputDom = this.$refs.url;
      inputDom.select();
      document.execCommand("copy");
    },
  }
}
</script>
