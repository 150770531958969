<template>
  <div>
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item active>行銷應用中心</b-breadcrumb-item>
          <b-breadcrumb-item active>集點卡管理</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>

    <b-card>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <h4 class="font-weight-bold">集點卡管理</h4>
        <div class="d-flex align-items-center">
          <b-button
            v-if="checkPermission([consts.REWARD_CARD_CREATE])"
            class="flex-shrink-0 mr-2"
            variant="primary"
            :to="{ name: 'RewardCardCreate' }"
          ><i class="fa fa-plus"></i>新增集點卡</b-button>
        </div>
      </div>

      <b-card-text>
        <b-overlay :show="showLoading" rounded="sm">
          <b-table striped hover responsive :items="rewardCards" :fields="fields" @sort-changed="handleSort">
            <template #cell(start_end_time)="data">
              <div>
                {{ dateFormatter(data.item.start_at) }}
              </div>
              <div>
                {{ dateFormatter(data.item.end_at) }}
              </div>
            </template>
            <template #cell(actions)="data">
              <copy-url-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_MISSION_LIST_VIEW])"
                :url="generateLiffUrl(`rewards-card/${data.item.id}`)"
              />
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_MISSION_LIST_VIEW])"
                variant="inverse-info"
                :to="{ name: 'RewardMissionList', params: { reward_card_id: data.item.id } }"
              >
                <span class="mdi mdi-paw"></span> 任務
              </b-button>
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_GIFT_LIST_VIEW])"
                variant="inverse-success"
                :to="{ name: 'RewardGiftList', params: { reward_card_id: data.item.id } }"
              >
                <span class="mdi mdi-gift"></span> 獎項
              </b-button>
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_CARD_LIST_VIEW])"
                variant="inverse-primary"
                :to="{ name: 'RewardCardView', params: { reward_card_id: data.item.id } }"
              >
                <span class="mdi mdi-eye"></span> 查看
              </b-button>
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_CARD_MODIFY])"
                variant="inverse-warning"
                :to="{ name: 'RewardCardEdit', params: { reward_card_id: data.item.id } }"
              >
                <span class="mdi mdi-lead-pencil"></span> 編輯
              </b-button>
              <b-button
                class="mr-1"
                v-if="checkPermission([consts.REWARD_CARD_DELETE])"
                variant="inverse-danger"
                @click="deleteRewardCard(data.item)"
              >
                <span class="mdi mdi-delete"></span> 刪除
              </b-button>
            </template>
          </b-table>
        </b-overlay>
        <CustomPagination
          :currentPage.sync="query.page"
          :totalRows="totalRows"
          :perPage.sync="query.per_page"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import rewardCardApi from "@/apis/reward-card";
import { mapState } from "vuex";
import CopyUrlButton from "@/components/Dashboard/CopyUrlButton.vue";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import generateLiffUrl from "@/mixins/Dashboard/liffUrlHelper";
import sweetAlertPresets from "@/mixins/Dashboard/sweetAlertPresets";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: {
    CopyUrlButton,
    CustomPagination,
  },
  mixins: [
    generateLiffUrl,
    sweetAlertPresets,
    paginationMixin,
  ],
  data() {
    return {
      consts: consts,
      showLoading: true,
      rewardCards: [],
      fields: [
        { key: "title", label: "集點卡名稱", sortable: true },
        { key: "start_end_time", label: "使用期間" },
        { key: "point_number", label: "卡面點數" },
        { key: "actions", label: "管理" },
      ],
      totalRows: 0,
      initialized: false,
      query: {
        per_page: 10,
        page: 1,
        sort_by: "created_at",
        order_by: "desc",
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route() {
      if (!this.initialized) return;
      this.fetchRewardCards();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchRewardCards();
    this.initialized = true;
    this.showLoading = false;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchRewardCards() {
      try {
        const params = { ...this.query };
        const response = await rewardCardApi.list(params);
        this.rewardCards = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取集點卡列表失敗", "error");
      }
    },
    async deleteRewardCard(rewardCard) {
      this.showLoading = true;
      try {
        if (false === await this.confirmDeletion({ text: `集點卡名稱：${rewardCard.title}` })) {
          return;
        }

        await rewardCardApi.remove(rewardCard.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchRewardCards();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
    dateFormatter: (value) => {
      if (!value) return "無";
      return format(new Date(value), "yyyy-MM-dd");
    },
  },
};
</script>
