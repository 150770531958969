import { mapState } from "vuex";
import { join } from "path";

export default {
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    generateLiffUrl(path) {
      const url = new URL("https://liff.line.me/");

      url.pathname = join(`${this.organization.liff_id}`, `${path}`);

      return url.toString();
    },
  },
};
